.SubscriptionWrapper {
  font-family: "Poppins";
  margin:0 1rem;
}
.subHeading {
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 3.5rem;
  margin-bottom: 2rem;
}
.subTitle {
  font-size: 24px;
  font-weight: 400;
}
.SubscriptionWrapper li {
  color: #474747;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: .2rem;
  display: flex;
  align-items: flex-start;
}
.SubscriptionWrapper ul{
  padding: 0;
}
.SubscriptionWrapper li img{
  height: 20px;
  width: 20px;
  margin-top: .5rem;
} 
.features{
  display: flex;
}
.features > div {
  width: 50%;
}
.features > div{
  display: flex;
  flex-direction: column;
}
.checkMark {
  height: 20px;
  width: 20px;
  margin-right: .2rem;
}

.cardWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 4rem 0rem;
}

/* card css */
.SubscriptionCard {
  display: flex;
  padding: 2rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  border-radius: 12px;
  border: 1px solid #b1b1b1;
  background: #fff;
  font-family: "Poppins";
  width: 22%;
  transition: 250ms ease-in-out all;
  position: relative;
}
.activeCard {
  transform: scaleY(1.2) scaleX(1.1);
  border: 2px solid #1aa05b;
}

.discount {
  position: absolute;
  right: 0%;
  top: 0%;
  display: flex;
  padding: .6rem 1.5rem;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px 12px;
  background: linear-gradient(91deg, #1aa05b 8.73%, #26a8d2 102.93%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  color: #fff;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}
.months {
  color: #1aa05b;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 26px;
  width: 100%;
}
.amountWrapper {
  width: 100%;
}
.amount {
  color: #000;
  font-size: 36px;
  font-weight: 500;
  line-height: 36px;
  width: 100%;
}
.amount span {
  color: #939ca3;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
}
.amountPerMonths {
  color: #4b4b4b;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  width: 100%;
  height: 20px;
}
.subscribeButton {
  display: flex;
  width: 70%;
  height: 40px;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #1aa05b;
  background: #fff;

  /* secondary button */
  box-shadow: -12px -12px 14px 0px rgba(213, 213, 213, 0.82) inset;
  color: #1aa05b;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
}
.activeBtn {
  color: #fff;
  border: none;
  background: #1aa05b;
  box-shadow: -24px -19px 14px 0px rgba(0, 0, 0, 0.3) inset,
    0px 4px 8px 0px rgba(26, 160, 91, 0.2), 4px 6px 14px 0px #3ae48d inset;
}
.freePlans li{
  color: #6D747A;
  font-weight: 300;
}
@media only screen and (max-width: 768px) {

  .SubscriptionCard{
    width: 46%;
    padding: 1.5rem .5rem;
  }
  .amountPerMonths{
    color: #4B4B4B;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
  .months{
    text-align: center;
  }
  .features{
    flex-direction: column-reverse;
  }
  .features >div{
    width: 100%;
  }
  .SubscriptionWrapper{
    margin:auto .5rem;
  }
  .subTitle,
  .SubscriptionWrapper li,
  .subHeading {
    font-size: 14px;
  }
  .subHeading{
    margin: 1rem 0;
  }
  .months {
    font-size: 16px;
  }
  .amount {
    font-size: 20px;
  }
  .amount span {
    font-size: 10px;
  }
  .subscribeButton,
  .activeBtn {
    display: flex;
    width: 76px;
    height: 28px;
    padding: 0.2rem 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 10px;
    line-height: 12px; /* 120% */
    letter-spacing: -0.1px;
  }
  .cardWrapper {
    justify-content: center;
    gap: 16px;
  }
  .SubscriptionCard {
    gap: 12px;
  }
  .activeCard {
    transform: scale(1.1);
  }
  .discount {
    padding: .2rem .2rem;
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
  }
  .SubscriptionWrapper li img {
    margin-top: 0;
  }
}
