@import url('https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@400;500;600&family=Righteous&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');

.App {
  
  margin: 0 2rem;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
  opacity: 0.75;
}

::-webkit-scrollbar-thumb {
  background-color: #bbbfc2;
  border-radius: 8px;
  opacity: 0.5;
  border: 2px solid #eeeeee;
}

::-webkit-scrollbar {
  background-color: #eeeeee;
  width: 10px;
  height: 10px;
  opacity: 0.75;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@media only screen and (max-width:768px) {
.App{
  margin: 0;
}
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input, input:before, input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
 } 
 input::placeholder{
  color: #A9A9A9 !important;
 }