
.article_title{
    margin-top: 86px;
    /* margin-bottom: 38px; */
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -0.01em;
    color: #1AA05B;
}
.article_text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 132%;
    margin-right: 30px;
    color: #000000;
}
.list_article{
    margin-bottom: 203px;
    margin-left: -10px;
}
.home_btn{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  background: #1AA05B;
border-radius: 20px;
border: 1px solid #1AA05B;
padding: 8px 16px;
margin-bottom: 40px;
margin-top: 5px;
transition: 0.5s;
}
.home_btn:hover{
  border: none;
  background: #9FDFBE;
  border: 1px solid #9FDFBE;
}
.article_image{
    width: 424px;
    height: 554px;
}
.langMerg{
  margin-bottom: -70px;
}
@media only screen and (max-width: 1440px) {
  .langMerg{
    margin-bottom: -80px;
  }
}
@media only screen and (max-width: 990px) {
.hr_line{
margin: 0 20px 0 20px;
}
    .article_title{
        margin-top: 15px;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.001em;
    }
    .article_text{
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
    }
    .list_article{
        margin-bottom: 50px;
        margin-left: -20px;

    }
}