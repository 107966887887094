.userLikeList .modal-dialog{
    width: 40vw;
    min-width: 40vw !important;
    max-width: 40vw !important;
}
.userLikeList .modal-body{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.userLikeList h4{
margin: 0 !important;
}
.userLikeList .modal-header{
    padding: .5rem !important;
}
.userLikeList a{
    color: black;
}
.userLikeList .userName{
display: flex;
gap: 8px;
/* justify-content: center; */
align-items: center;
}

.saveIconDiv{
    margin-top: .2rem;
}

.saveIconDiv,.likeIconDiv{
cursor:pointer;
}
.likeTotalCount{
    margin-top: 0.1rem;
    cursor: pointer;
}
.saveTotalCount{
    margin-top: 0.3rem;
    cursor: pointer;
}

@media only screen and (max-width: 780px) {
    .userLikeList .modal-dialog{
        min-width: 90vw !important;

        max-width: 90vw !important;
    }
    .saveIconDiv{
        margin-top: .1rem;
    }
    .likeTotalCount{
        margin-top: .1rem;
    }
    .saveTotalCount{
        margin-top: 0rem;
    }
}