.cardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}
.expiryDate_CVV {
  display: flex;
  gap: 16px;
}
.expiryDateWrap,
.cvvWrap {
  width: 40%;
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.expiryInput{
    position: relative;
}
.expiryInput input{
    width: 100%;
    display: flex;
    align-items: center;
  }
.calenderIcon{
position: absolute;
height: 20px;
width: 20px;
right: 5%;
bottom: 25%;
}
.cvvWrap{
    width: 20%;
}

.cardContainer input {
  display: flex;
  height: 40px;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  row-gap: 60px;
  flex-wrap: wrap;
  border-radius: 12px;
  border: none;
  background: var(--grey-hover, #f8f9fa);
  box-shadow: 4px 5px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}
.cardContainer input:focus{
    outline: none;
}
