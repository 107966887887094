.footerSizeTaker{
    display: none;
}

.footer_content{
    background-color: #1AA05B !important;
    margin: 0px;
    padding: 0px;
}
.footer_res_content{
    background: #1AA05B ;
    margin: 0px;
    padding: 0px;
}
.footer_text{
    margin: 1.5rem 0;
}
.footer_text p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
}
.footer_text p a{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    text-decoration: none;
    transition: .5s;
}
.footer_text p a:hover{
  color: #000000;
}
.last_content{
    margin: 0;
}
.footer_logo{
    width: 112px;
    height: 154px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.copyrightFooterText{
    color: white;
    padding-bottom: 1rem;
    font-weight: 500;
}

@media only screen and (max-width: 990px) {
  .footer_top_margin{
    /* margin-top: 212px; */
    /* position: absolute; */
    bottom: 0;
    width: 100%;
  }
  .footer_top_margin_res{
    /* margin-top: 212px; */
    /* position: absolute; */
    bottom: 0;
    width: 100%;
  }
  .footerSizeTaker{
    display: block;
    /* height: 155px; */ 
  }
    .footer_content{
        height: auto;
    }
    .footer_res_content{
        height: auto;
    }
    .footer_text{
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .footer_text p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 8px;
        margin-top: 0 !important;
        padding-top: 0 !important;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
    .footer_logo{
        width: 75px;
    height: 102px;
        margin-top: 0;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0;
    }
}
@media only screen and (max-width: 600px) {

    .footer_content{

            background-color: #1AA05B !important;
            margin: 0;
            padding: 0;
            height: auto !important;
        
    }
   .footer_text p a,.copyrightFooterText{
       font-size: 14px;
    }

}

    @media only screen and (min-width: 768px) and (max-width: 959px){
        .footer_content{

            background-color: #1AA05B !important;
            margin: 0;
            padding: 0;
            height: auto !important;
        
    }
        }