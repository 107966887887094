.select-border{
    border: none;
    border-radius: 5px;
}
.error-border{
    border: 1px solid#DD1D43;
    color: #DD1D43;
    border-radius: 5px;
}
.heroImage{
    background: url('../../asstes/image\ screen.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 321px;

}
.profileModal{
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
}
.heroImage1{
    background: url('../../asstes/carousel4.jpg');
    
    background-repeat: no-repeat;
    width: 100%;
    height: 321px;
}
#iim{
    height:321px;
    width: 100%;
}
.heroImageRes{
    background: url('../../asstes/Rectangle\ 145.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 204px;
    background-size: cover;
    background-position: center;

}
.heroTitleName{
    padding-top: 9.375rem;
}
.Sec_submit_btn{
    padding:.5rem 1rem;
    background: #FFFFFF;
    border-radius: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: -0.01em;
    color: #1AA05B;
    border: none;
    position: relative;
    bottom: 10px;
}
.Sec_submit_btn:hover{
    background: #90d3b0;
}
.error_text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #DD1D43;
}
select{
    color: black;
    background:  #F8F9FA;
    box-shadow: 4px 5px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
}

select option{
    background: #F8F9FA;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: 4px 5px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
} 
.loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #1AA05B;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
    margin-top: 1.25rem;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
@media only screen and (max-width: 990px) {
    .heroTitleName{
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 16px;
    padding-top: 5.6rem;
    }
    .select-border {
      font-size: 12px;
      padding: .4rem;
  }
  .profileModal{
    height: 30vh;
    width: 60vw;
}
}