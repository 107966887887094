.optionModal {
  min-height: 50vh;
}

.closebutton {
  cursor: pointer;
  color: #6d747a;
}
.getStarted {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
.primaryButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 24px;
  padding: 0.3rem 0.8rem;
  background: #1aa05b;
  border: none;
  min-width: 120px;
  box-shadow: -24px -19px 14px 0px rgba(0, 0, 0, 0.3) inset,
    0px 4px 8px 0px rgba(26, 160, 91, 0.2), 4px 6px 14px 0px #3ae48d inset;
}
.primaryButton:hover,
.secondaryButton:hover {
  box-shadow: none;
}
.primaryButton:disabled {
  box-shadow: none;
}
.secondaryButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: #1aa05b;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  min-width: 120px;
  padding: 0.4rem 0.8rem;
  line-height: 24px; /* 100% */
  letter-spacing: -0.24px;
  border-radius: 24px;
  border: 1px solid #1aa05b;
  background: #fff;
  box-shadow: -12px -12px 14px 0px rgba(213, 213, 213, 0.82) inset;
}
.TertiaryButton {
  display: flex;
  height: 40px;
  padding: 0.4rem 0.8rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 2px solid #1aa05b;
  color: #1aa05b;
  font-weight: 600;
}

@media only screen and (max-width: 888px) {
  .primaryButton,
  .secondaryButton {
    font-size: 10px;
    height: 28px;
    padding: 0rem 0.5rem;
    min-width: 85px;
  }
  .secondaryButton img {
    height: 14px;
  }
  .TertiaryButton{
    font-size: 10px;
    height: 28px;
    padding: 0rem 0.5rem;
  }
}
