/* Modal.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(50px);
  background:  rgba(213, 179, 154, 0.40);

/* background blur */
backdrop-filter: blur(10px);
  /* background-color: rgba(0, 0, 0, 0.7); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  position: relative;
}

.modalImage {
  max-width: 100%; 
  max-height: 75vh;   
  display: block; 
  margin: 0 auto
}

.modalText {
  margin: 10px 0;
}

.modalCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.readMore,.closeButton {
  height: 20px;
}
.modalCloseButton{
    background:none;
    border: none;
}
.closeIcon{
    height: 24px;
    width: 24px;
}
.readMoreButton {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: None;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  color: #1aa05b;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 26px; /* 216.667% */
  margin-top: 0.4rem;
}
@media (max-width: 768px) {
    .modalContent{
        width: 90vw;
    }
    .modalImage{
        width: 100%;
    }
}