/* .App {
  display: flex;
  width: 100%;
  align-items: stretch;
} */

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700";

.DashboardWrapper {
  display: flex !important;
  width: 100%;
  align-items: stretch;
}

p.ml-3 {
  margin-left: 1rem !important;
}
/* a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
} */

/* a tag */
.dash_sidebar_a,
.dash_sidebar_a:hover {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}
.dash_sidebar_a:focus{
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}
.dash_sidebar_aa{
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}
/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #d5b39a;
  color: #ffffff !important;
  margin-left: -250px;
  transition: all 0.5s;
  position: fixed !important;
  font-weight: bold;
  left: 0;
  height: -webkit-fill-available;
  z-index: 1;
}
.mr-2{
  margin-right: 0.5rem!important;
}
.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
  position: relative;
}

.sidebar-header {
  background: #d5b39a;
  color: black !important;
  font-weight: bold;
}

.sidebar-header h3 {
  color: black;
  padding: 1em;
  font-weight: bold;
}

.sidebar ul p {
  color:black;
  padding: 10px;
  font-weight: bold;
}

.collapse.show,
.collapsing {
  background: #313b4c;
}

.nav-item {
  /*color: #ced4da;*/
  color: black !important;
  font-weight: 300;
  font-weight: bold;
}

.nav-item.open {
  color: black !important;
  background: #313b4c;
  font-weight: bold;
}

.nav-item.active {
  color: black;
  background: #2d3646 !important;
}

.nav-item:hover {
  background: #1AA05B !important;
  color: black;
  font-weight: bold;
  transition: 0.2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
.content {
  padding: 20px;
  height: 100vh;
}
.content.is-open{
  margin-left: 250px;
}
.dropdown-menu{
  background-color: #d5b39a !important;
}
@media only screen and (max-width: 575.98px) {

  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
    position: relative !important;
  }

  .sidebar.is-open > .sidebar-header button {
    display: block;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}
