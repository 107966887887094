.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dropdownAndVars {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.requiredVars {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.requiredVars p {
    padding: 0;
    margin: 0;
    color: red;
}