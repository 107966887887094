.AddResourcesForm,.AddResourcesForm > div{
    display: flex;
    gap: 16px;
}
.AddResourcesForm{
    flex-direction: column;
}
.AddResourcesForm input,.AddResourcesForm textarea{
    width:30vw
}
.AddResourcesForm button{
    width:150px
}
@media only screen and (max-width: 575.98px) {
    .AddResourcesForm input,.AddResourcesForm textarea{
        width:100%;
    }
    .AddResourcesForm button{
        width:150px
    }   
}