.log_in {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 48px;
  letter-spacing: -0.01em;
}
.modal.show .modal-dialog {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.fade{
margin-top: 0 !important;
}
.modal-content{
  width: 100%;
}
.pinInput {
  display: flex;
  justify-content: center;
  margin: 1rem;
}
.resendOTP {
  color: #1aa05b;
  background: none;
  border: none;
}
.OTPinput {
  border: none;
  border-bottom: 2px solid #1aa05b;
  color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  width: 30px;
  margin-right: 0.625rem;

}
.signIn_body label{
  margin-bottom: -1.25rem;
}
.signinCloseIcon{
  cursor: pointer;
  color: #6D747A;
}
.OTPinput:focus {
  outline: none;
}
.otp1{
  left: 22px;
}
.otp2{
  left: 60px;
}
.otp3{
  left: 100px;
}
.otp4{
  left: 137px;
}
.otp5{
  left: 176px;
}
.submit_btn,
.submitOTP_btn {
  width: 237px;
  padding: 0.5rem 1rem;
  border-radius: 24px;
  background: #1AA05B;
  box-shadow: -24px -19px 14px 0px rgba(0, 0, 0, 0.30) inset, 0px 4px 8px 0px rgba(26, 160, 91, 0.20), 4px 6px 14px 0px #3AE48D inset;
}
.submitOTP_btn {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  border-radius: 24px;
  background: #1aa05b;
  border: none;
  /* primary button */
  box-shadow: -24px -19px 14px 0px rgba(0, 0, 0, 0.3) inset,
    0px 4px 8px 0px rgba(26, 160, 91, 0.2), 4px 6px 14px 0px #3ae48d inset;
}
.signOTP_btn {
  width: 237px;
  padding: 0.5rem 1rem;
  border-radius: 24px;
  border: 1px solid #1aa05b;
  background: #fff;
  /* secondary button */
  box-shadow: -12px -12px 14px 0px rgba(213, 213, 213, 0.82) inset;
  color: #1aa05b;
  font-size: 20px;
  font-weight: 600;
}
.signIn_body {
  background: white;
  border-radius: 12px;
}
.login_input {
  width: 367px;
  background: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 5px 10px;
  height: 40px;
  border-radius: 12px;
  box-shadow: 4px 5px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}
.forgot_pass {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
}
.containerr {
  display: block;
  position: relative;
  padding-left: 2.188rem;
  margin-bottom: .7rem;
  margin-top: .1rem;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerr input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 3px solid #8C9F95;
  border-radius: 4px;
}
input[type=radio] {
  accent-color: #1aa05B;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerr input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerr .checkmark:after {
  left: 5px;
  top: 1.5px;
  width: 5px;
  height: 10px;
  border: solid #1AA05B;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
input[name="checkmark"]:checked + .checkmark {
  border: 3px solid #1AA05B; /* Change to green when checked */
}


@media only screen and (max-width: 990px) {
  .log_in {
    font-size: 20px;
  }
  .login_input {
    width: 268px;
  }
  .modal.show .modal-dialog {
    max-width: fit-content !important;
  }
  .signIn_body{
    padding: 0.3rem;
  } 
}