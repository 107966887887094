.container {
  max-width: 1250px;
}

.pencilButton {
  position: absolute;
  z-index: 2;
  right: 18px;
  top: 12px;
  border: none;
}

.newSaveStrParent {
  background: #1aa05b;
  overflow: hidden;
  padding: 1px;
}

.saveStrParent {
  /* width: 42.869vw; */
  width: 619px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 24px;
  border: 1px solid #1aa05b;
  background: #fff;
  box-shadow: -12px -12px 14px 0px rgba(213, 213, 213, 0.82) inset;
  margin-top: 15px;
}

.saveStrParent span {
  color: var(--brand-color-green, #1aa05b);
  text-align: left;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  letter-spacing: -0.2px;
  /* display: block !important; */
}

.saveStrParent svg path {
  fill: #1aa05b;
}

.saveStrParentActive {
  margin-top: 15px;
  /* width: 42.869vw; */
  width: 619px;
  cursor: pointer;
  overflow: hidden;
  padding: 1px;
  border-radius: 24px;
  background: #1aa05b;
  box-shadow: -24px -19px 14px 0px rgba(0, 0, 0, 0.3) inset,
    0px 4px 8px 0px rgba(26, 160, 91, 0.2), 4px 6px 14px 0px #3ae48d inset;
}

.saveStrParentActive span {
  color: var(--grey-white, #fff);
  text-align: left;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  letter-spacing: -0.2px;
  /* display: block !important; */
}

.saveStrParentActive svg path {
  fill: #fff;
}

.filter_bTn {
  color: #1aa05b;
  background: white;
  display: flex;
  transition: 0.5s;
  position: relative;
  border-radius: 27px;
  width: 98px;
  cursor: pointer;
  padding: 0.188rem 0.625rem;
}
.filter_bTn:hover {
  color: #ffffff;
  background: #1e6540;
}
.filtericon2 {
  position: relative;
  padding-left: 10px;
  display: block;
}
.filtericon3 {
  position: relative;
  padding-left: 10px;
  display: none;
}
.filter_bTn:hover .filtericon2 {
  display: none;
}
.filter_bTn:hover .filtericon3 {
  display: block;
}
.filtericon3 {
  position: relative;
  padding-left: 10px;
}
.button:hover {
  position: relative;
  left: -25px;
  border-radius: 27px;
  width: 98px;
  cursor: pointer;
  background-color: #4caf50;
  transition-duration: 0.4s;
}
.model-content {
  top: 125px;
}
.savStrParent2 {
  background: #1aa05b;
  overflow: hidden;
  height: 33px;
  padding: 1px;
}
.button:hover {
  background-color: blue;
  color: red;
}
.headText {
  font-size: 21px;
  font-weight: 600px;
}
.subscriptionTitle{
  font-size: 28px !important;
}
.str_text_left {
  margin-top: 1.875rem;
}
.savestr_head,
.padalogicalTitle {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 1rem;
}
.padalogicalTitle {
  color: #1aa05b;
  margin-top: 2.188rem;
}
.learningOutcome {
  font-weight: 400;
}
.loadingWrap {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterBtn {
  border-radius: 27px;
  width: 90px;
  cursor: "pointer";
}
.filterBtn > span {
  color: #1aa05b;
}
.pedalogicalText {
  font-size: 22px !important;
  color: #ff3131;
  font-style: italic !important;
  margin-bottom: 0;
  margin-top: 0.3rem;
  text-transform: uppercase;
}
.savestr_body {
  display: -webkit-box;
  width: 470px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
  letter-spacing: -0.2px;
}
.single_str_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.uni_id {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #6d747a;
  margin: 0;
}
.dev_dom_single {
  margin-top: 26px;
  margin-bottom: 20px;
}
.dev_dpm_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #08090a;
  margin-top: 10px;
  margin-left: 10px;
  text-align: left;
}
.dev_dom_bg {
  background: #f4f8f7;
  height: fit-content;
  border-radius: 2px;
  position: relative;
  padding: 18px 14px;
}
.icon_bg {
  background: #ffffff;
  border-radius: 17px;
}
.Dev_dom {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  background: #1aa05b;
  border-radius: 2px;
  padding: 5px 10px;
}

.count_num {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.05em;
  color: #a4a8ac;
  margin-left: 0.5rem;
  cursor: pointer;
}

.comment_div {
  margin: 0.625rem 0px 0.625rem 0px !important;
  border: 2px solid #1aa05b;
  border-radius: 8px;
  padding: 1.5rem 2.5rem;
  margin-left: auto !important;
}
.comment_div_p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #08090a;
}
.comment_input {
  border: 2px solid #1aa05b;
  padding: 0.625rem 5.6rem 0.625rem 1.25rem;
  background: #f4f8f7;
  border-radius:12px ;
}
.comment_submit {
  margin-top: -2.5rem;
  margin-right: 0.625rem;
  background: #1aa05b;
}
.comment_submit input {
  background: #1aa05b;
  box-shadow: -24px -19px 14px 0px rgba(0, 0, 0, 0.3) inset,
    0px 4px 8px 0px rgba(26, 160, 91, 0.2), 4px 6px 14px 0px #3ae48d inset;
  border-radius: 20px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
  letter-spacing: -0.01em;
  padding: 5px 16px;
}
.see_comment {
  background: #f4f8f7;
  border: 0.5px solid rgba(47, 50, 57, 0.3);
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 5px;
  padding: 0px;
  cursor: pointer;
}
.col-md-1 bg-white py-1 px-3 :hover {
  background-color: #555555;
  color: white;
}
.see_comment p {
  color: #8E8E8E;
  line-height: 25px; 
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  padding: 16px 12px 16px 24px;
  background: #F8F9FA;
}
.arrow {
  width: 5px;
}
.comment_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #08090a;
}
.comment_span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  color: #6d747a;
  padding-left: 5px;
}
.comment_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #8e8e8e;
}
.icons_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  background: #1aa05b;
  border-radius: 2px;
  padding: 2px 20px;
  margin-left: -68px;
}
.threeIcons-nuns {
  width: 60px;
  height: 64px;
}
.str_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #474747;
  margin-bottom: 0.125rem;
  margin-right: -0.313rem;
  text-transform: uppercase;
}
.user_image {
  width: 26px;
  height: 26px;
  border-radius: 10000px;
}
.res_btn_icon_user {
  width: 40%;
  display: flex;
  align-items: center;
}
.str_title_user {
  margin-bottom: 21px;
  margin-top: 35px;
}
.largeCommentContainer,
.comment_div {
  background: #f4f8f7;
}
.filter_btn_container {
  right: 35px;
}
.disableCopy {
  user-select: none;
}
.saveStrParent2 {
  background: #1aa05b;
  overflow: hidden;
  padding: 0.313rem;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.GoBack {
  position: absolute;
  left: 10px;
  display: flex;
  gap: 12px;
  color: #fff;
  text-decoration: none;
}
.GoBack a:hover {
  color: #fff;
}
.editStrategy,
.markUsed,
.UsedStartegy {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0.6rem 0.8rem;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  background: none;
  border: none;
  border-radius: 24px;
  height: 44px;
}
.editStrategy {
  border: 2px solid #1aa05b;
}
.markUsed,
.UsedStartegy {
  color: #fff;
  background: #1aa05b;
  box-shadow: 0px 4px 8px 0px rgba(26, 160, 91, 0.2);
}
.strategytext {
  border: none;
  resize: none;
  margin-bottom: 1rem;
  outline: none;
  background-color: burlywood;
}
.strategytext::placeholder {
  color: black;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}
.strategytext::selection {
  border: none;
}
.textContainer {
  border-radius: 16px 16px 0px 0px;
  background: #f4f8f7;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
}
.textContainer .userdetailsBox {
  display: flex;
  width: fit-content;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  color: var(--grey-dark, #939ca3);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 0px solid var(--gray-light, #b1b1b1);
  background: var(--grey-hover, #f8f9fa);
  box-shadow: 10px 20px 20px 0px rgba(255, 255, 255, 0.2),
    4px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.textContainer .userdetailsBox img{
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
input:disabled {
  box-shadow: none;
  background: #8C9F95;
}
select:disabled {
  background: #ddd;
}
@media only screen and (max-width: 888px) {
  .Dev_dom {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    background: #1aa05b;
    border-radius: 2px;
    padding: 5px 10px;
  }
}
@media only screen and (max-width: 768px) {
  .dev_dpm_text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 11px;
    color: #08090a;
    margin-top: 0px;
    margin-left: 0px !important;
    text-align: center !important;
    margin-bottom: 5px;
  }
.see_comment p {
  font-size: 14px;
  padding: .5rem;
}
  .saveStrParent {
    width: 51vw;
    color: var(--brand-color-green, #1aa05b);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
    letter-spacing: -0.1px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    margin-top: 0.3rem;
  }

  .saveStrParentActive {
    width: 45.55vw;
  }

  .saveStrParentActive span {
    color: var(--grey-white, #fff);
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 150% */
    letter-spacing: -0.08px;
    display: block !important;
  }
  .saveStrParent span {
    color: var(--brand-color-green, #1aa05b);
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 120% */
    letter-spacing: -0.1px;
    display: block !important;
  }
  .comment_div_p {
    font-size: 12px;
  }
  .comment_submit input {
    font-size: 12px;
  }
  .newLine {
    font-size: 12px;
  }
  .textContainer .userdetailsBox {
    font-size: 12px;
  }
  .textContainer .userdetailsBox img{
    height: 24px;
    width: 24px;
  }
}
@media only screen and (max-width: 990px) {
  .filter_btn_container {
    right: 20px;
  }

  .dev_dpm_text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 11px;
    color: #08090a;
    margin-top: 0px;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  .headText {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    display: block !important;
  }
  .subscriptionTitle{
    font-size: 12px !important;
  }
  .savestr_head {
    font-weight: 500;
    font-size: 12px !important;
    line-height: 15px !important;
    margin-top: 15.5px;
  }

  .savestr_body {
    display: -webkit-box;
    width: calc(90vw - 16px);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    overflow: hidden;
    color: var(--gray-text, #474747);
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
    letter-spacing: -0.4px;
  }
  .single_str_head {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0 !important;
    line-height: 12px;
  }
  .str_name {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
  .pedalogicalText {
    font-size: 12px !important;
  }
  .comment_div {
    padding: 10px 10px;
    border: 1px solid #1aa05b;
  }

  .comment_head {
    font-size: 12px;
    margin: 0;
  }
  .comment_span {
    font-size: 10px;
  }
  .comment_text {
    font-size: 12px;
    line-height: 19px;
  }
  .count_num {
    margin-left: 5px;
  }
  .uni_id {
    font-size: 14px;
  }
  .str_text_left {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .filter_bTn {
    position: relative;
    border-radius: 27px;
    width: 75px;
    cursor: pointer;
    padding: 3px 10px;
    margin-top: -3px;
    margin-right: -7px;
    font-size: 14px;
  }
  .filter_bTn:hover {
    color: #1aa05b;
    background: white;
  }
  .filtericon2 {
    padding: 0;
  }
  .filtericon3 {
    padding: 0;
  }
  .user_image {
    width: 16px;
    height: 16px;
  }
  .res_btn_icon_user {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  .str_titlee {
    margin-bottom: 0px;
  }
  .str_title_user {
    margin-bottom: 5px;
    margin-top: 15.5px;
  }
}
