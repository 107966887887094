.profile_container{
  font-family: 'Poppins', sans-serif;
}
.button-wrapper {
    position: relative;
    width: 150px;
    text-align: center;
    margin:auto ;
  }
  .hover-white:hover {
    filter: brightness(0) invert(1);
  }
  .arrow {
    margin-left: 6px;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    transition: transform 0.3s; 
  }
  
  .up {
    border-width: 0 5px 8px 5px;
    border-color: transparent transparent black transparent;
    transform: rotate(180deg);
  }
  
  .down {
    border-width: 8px 5px 0 5px;
    border-color: black transparent transparent transparent;
    transform: rotate(-180deg);
  }
  
  .button-wrapper span.label {
   
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: #00bfff;
    cursor: pointer;
    color: #fff;
    padding: 10px 0;
    text-transform:uppercase;
    font-size:12px;
  }
  
  #upload {
      display: inline-block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
  }
.profile_school{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #474747;
    margin-top: 2rem;
}
.profile_Name{
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: .2rem;
}
.profile_Organization{
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.viewMyStrategies{
  display: flex;
width: 100%;
height: 56px;
padding: 1rem 1.5rem;
align-items: center;
gap: 60px;
background: transparent;
border:none;
color: #1AA05B;
font-size: 20px;
font-style: normal;
font-weight: 600;
white-space: nowrap;
}
.welcomeUser{
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
}
.side_profile{
  width: 390px;
  height: fit-content;
  border-radius: 24px;
  background: #FFF;
  box-shadow: -8px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
/* Add this CSS to your stylesheet */
button:hover .hover-white {
  filter: brightness(0) invert(1);
}
.form_container{
  width: 725px;
  height: 778px;
  font-size: 24px;
  background: #F8F9FA;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
}
.profile_input{
  display: flex;
  margin-left: 2.5rem;
  width: 370px;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 40px;
  border-radius: 12px;
  border:none;
  background: #FFF;
  box-shadow: 4px 5px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
}
.input_div{
  margin-bottom: 2rem;
}
.static_data{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
padding-left: 12px;

}
.input_label{
  color: #000;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button_div{
  margin-top:5rem;
}
.save_change_btn{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: white;
  border: 1px solid #169462;
  border-radius: 32px;
  background: #169462;
  padding: 5px 10px;
  
  transition: 0.5s;
}
/* .save_change_btn:hover{
  background: #9FDFBE;
  color: #FFFFFF;
} */
.change_btn{
  display: flex;
  width: 166px;
  padding: 8px 12px;
  align-items: center;
  gap: 5px;
  border-radius: 12px;
border: 1px solid #1AA05B;
background: #FFF;
color: #1AA05B;
text-align: center;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 12px; /* 120% */
letter-spacing: -0.1px;
}
.prfile_pic{
  border-radius: 8px;
}
.button-wrapperr{
  margin-top: 21px;
  margin-left: 22px;
  margin-bottom: 37px;
  margin-right: 7px;
}
.city-profile{
  width: 350px;
}
.Email_Edit{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
  color: #1AA05B;
  cursor: pointer;
  transition: 0.5s;
}
.Email_Edit:hover{
  color: #51D390;
}
.edit_al{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  background: #1AA05B;
  border-radius: 4px;
  border: 1px solid #1AA05B;
  padding: 8px 15px 8px 15px;
  transition: 0.5s;
  cursor: pointer;
}
.edit_al:hover{
  background: rgba(26, 160, 91, 0.5);
  border: 1px solid rgba(26, 160, 91, 0.5);
}
input[type="text"][disabled] {
  color: #000;
}
.res_userName{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #08090A;
}
.select_div select {  
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select_div select.profile_input {
  background-image:url(../../asstes/icons/arrow_select.svg);
  background-position:
    calc(100% - 15px) calc(1em),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
}
.profileBtn{
  background: transparent;
  color:#1AA05B;
  border: 1px solid #1AA05B;
  font-size: 16px;
  font-weight: 700;
  border-radius: 20px;    
  padding: 8px 16px;
  gap: 10px;
  transition: 0.5s;
  
}
.profileBtn:hover{
  background: #1AA05B;
  color: #ffff;
}


#rp{
position: "relative";
padding: "4px";}
#wb{
  border-radius: 1000px;
  width: 40px;
}
#wb1{
  border-radius: 1000px;
  width: 120px;
  height: 120px;
}
#fc{
  display:"flex";
   place-content:"center";
}
#dgm{
  display: grid;
gap:14px;
margin-top: -20px;
}
#h10{
  height: 10px;
}
#p2{
  padding: 2%;
}
#pm{
  padding:1%;
  margin-top: 10px;
}
#pt{
  padding-top: 35px;
}
#bmm{
  border: "1px solid #CED4DA";
  margin-top: "5px";
   margin-bottom: '0px';
   margin-left: "15px";
   margin-right: "15px";
}
#bwb{
  border:"1px solid black";
   background-color:"white";
    box-shadow:"1px 2px 1px 1px black";
}
#mtop{
  margin-top: -12px;
}
#bbb{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.change_btn:hover{
  color: white;
  border-radius: 12px;
  background: #1AA05B;
  /* primary phone */
  box-shadow: -16px -8px 11px 0px rgba(0, 0, 0, 0.30) inset, 0px 2px 4px 0px rgba(26, 160, 91, 0.20), 2px 2px 8px 0px #3AE48D inset;
}

.upload_Str_btn{
  background: #1AA05B;
  border-radius: 20px;
  padding: 5px 24px;
  gap: 10px;
  border: 1px solid #1AA05B;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  transition: 0.3s;
  position: relative;
    bottom: 20px;
}
.upload_Str_btn:hover{
  border: none;
  background: #9FDFBE;
  border: 1px solid #9FDFBE;
}
/* .profileOptions a{
display: flex;
justify-content: space-between;
} */
.authBtn_p{
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: var(--brand-color-green, #1AA05B);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: -0.2px;
  background: none;
  border: none;
  width: 100%;
  white-space: nowrap;
}
.authBtn_p:hover{
  background: none !important;
  color: #1AA05B !important;
}

a{
  text-decoration: none;

}
.lowerOptions{
  margin-top: 24px;
  gap:24px
}
.profileOption{
  display: flex;
width: 100%;
padding: 16px 24px;
align-items: center;
gap: 10px;
color: var(--brand-color-green, #1AA05B);
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 120% */
letter-spacing: -0.2px;
border: none;
background: transparent;

}
.profileOption:hover,.viewMyStrategies:hover{
  border-radius: 24px;
background: #1AA05B;
color: #FFF;
/* primary button */
box-shadow: -24px -19px 14px 0px rgba(0, 0, 0, 0.30) inset, 0px 4px 8px 0px rgba(26, 160, 91, 0.20), 4px 6px 14px 0px #3AE48D inset;
}
.authBtn_p:hover{
  background: #1AA05B;
  color: #ffff;
}

.authBtn_p:disabled{
  background: gray;
  color: #ffff;
}
.viewBtns:disabled{
  color: gray !important;
  background:transparent !important;
}
@media only screen and (max-width: 1300px) {
.container{
  width:100%;
  padding: .5rem !important;
}
#bbb{
  margin-left: 1rem !important;
  padding: 1rem 0rem;
}
#bwb{
  margin-left: 1rem !important;
padding: 1rem  0rem !important;
}
.profileFormWrapper{
  /* padding: 3rem 0rem !important; */
}
.profile_input{
  margin-left: 1rem;
}
.side_profile{
  width: 340px;
  font-size: 16px !important;
}
.profile_input{
  width: 340px;
}
}
@media only screen and (max-width: 990px) {
  .welcomeUser{
    font-size: 32px;
  }
  .viewMyStrategies,.profileOption{
    font-size: 16px;
    padding: 1rem;
  }
.profileFormWrapper{
  padding: 3rem 0rem !important;
  width: 95%;
}
#bwb{
  width: 100% ;
}
.side_profile{
  width: 300px !important;
}
.profileOption{
  width: 95%;
  padding: 1rem;
}
.form_container{
  width: 100%;
  max-height: 300px;
  font-size: 14px;
  background: #fff;
  box-shadow: none;
}
.profile_container{
  background: #fff;
}
.input_label{
  font-family: 'Inter';
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  margin-right: 0px;
}
.profile_input{
  /* margin-bottom: 12px; */
  background: #fff;
  width: 260px;
  font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  padding: 0.8rem 1rem;
  border-radius: 4px;
}
.state_input{
  border: 1px solid #939CA3;
  border-radius: 4px;
}
.state_inputt{
  border: 1px solid #939CA3;
  border-radius: 4px;
  width: 350px;
}
.save_change_btn{
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: -0.01em;
  padding: 4px 6px;
}
#upload {
  width: 120px;
  height: 120px;
  top: 0;
  left: 15px;
}
.city-profile{
  width: 222px;
}
.Email_Edit{
  font-weight: 700;
font-size: 8px;
line-height: 12px;
padding: 5px 10px;
}
.edit_al{
  padding: 4px 12px;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
}
.static_data{
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
}
.input_div{
  margin-bottom: 1rem;
}
.button_div{
  margin-top: 12px;
}
.profileBtn{
  padding: 6px 16px;
  font-size: 12px;
}
.upload_Str_btn{
  background: #1AA05B;
  border-radius: 20px;
  padding: 5px 20px;
  gap: 10px;
 
  border: 1px solid #1AA05B;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  transition: 0.3s;
}
.upload_Str_btn:hover{
  border: none;
  background: #9FDFBE;
  border: 1px solid #9FDFBE;
}
}
@media only screen and (max-width: 767px) {
  .profileFormWrapper{
    width: 65% !important;
    margin: 0;
  }
 
  .input_div{
    justify-content: flex-start !important;
  }
  .input_label{
    font-weight: 400;
    font-size: 10px;
    width: 30%;
  }
  .profile_input{
    font-size: 10px;
    width: 200px;
    height: 30px;
    padding: 0rem .5rem !important;
  }
  .button-wrapper{
    margin:0;
  }
  .profile_school{
    margin: 0;
  }
  .profile_school{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .res_userName{
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
  }
  .button-wrapper{
    width: 70px;
  }
  #upload {
    width: 40px;
    height: 40px;
    top: 0;
    left: 15px;
  }
  .typeWrapper{
    width: 95% !important;
    gap: 4px;
  }
  .typeButton{
    color: #1AA05B;
    background: none;
    border: none;
    padding: 4px 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    width: 166px;
    border-radius: 12px;
    text-align: left;
  }
  .typeButton:hover{
    color: #FFF;
background:  #1AA05B;
box-shadow: -12px -9.5px 7px 0px rgba(0, 0, 0, 0.30) inset, 0px 2px 4px 0px rgba(26, 160, 91, 0.20), 2px 3px 7px 0px #3AE48D inset;
  }
  .change_btn{
    width: calc(100vw - 32px);
  }
}


@media only screen and (max-width: 500px) {
  .welcomeUser{
    font-size: 1rem;
  }
  .input_label{
    width: 100px;
  }
  .profileFormWrapper{
    width: 95% !important;
    margin: 0!important;
  }
  .profile_input{
    width: 180px;
    height: 30px;
    padding: 0rem .5rem !important;
  }
  .profileFormWrapper{
    padding:0rem !important;
  }
  #bwb{
    margin: 0 !important;
  }
}

@media screen and (min-width:1024px) {
  .typeWrapper{
    min-width: 619px;
  }
}

.mobilePaddings{
    margin: 0 !important;
    padding-left: 14px !important;
    padding-top: 8px !important;
}