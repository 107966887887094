*{
    font-family: 'Poppins', sans-serif;
}
.authBtn{
    background: transparent;
    color:#1AA05B;
    border: 1px solid #1AA05B;
    font-size: 16px;
    font-weight: 700;
    border-radius: 20px;    
    padding: 0.5rem 1rem;
    gap: 10px;
    transition: 0.5s;
    border-radius: 24px;
    box-shadow: -12px -12px 14px 0px rgba(213, 213, 213, 0.82) inset;
}

.authBtnn{
    background: #1AA05B;
    color:#ffff;
    border: 1px solid #1AA05B;
    font-size: 16px;
    font-weight: 700;
    border-radius: 20px;    
    padding: .5rem 1rem;
    gap: 10px;
    transition: 0.5s;
    box-shadow: -24px -19px 14px 0px rgba(0, 0, 0, 0.30) inset, 0px 4px 8px 0px rgba(26, 160, 91, 0.20), 4px 6px 14px 0px #3AE48D inset;
}

.logo2_img{
    height: 80px;
}
.logo-res{
    width: 70px;
    height: 45px;
}
.profile_a{
    position: relative;
    right: 0;
    cursor: pointer;
}
.profile_section{
    position: absolute;
    width: 250px;
    background-color: #F8F9FA;
    right: 0;
    top: 0;
    margin-top: 1.25rem;
    margin-right: 5rem;
    border-radius: 5px;
    z-index: 1;
}
.navLink{
    text-decoration: none;
    color: black;
    cursor: pointer;
}
.navLink:hover{
    color: #1AA05B;
}
.drop_down_icon{
    width: 30px;
    height: 30px;
}
.Go_back{
  background: #1AA05B;
border-radius: 24px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 24px;
text-decoration: none;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
color: #FFFFFF;
transition: 0.5s;
}
.Go_back:hover{
  background: rgba(26, 160, 91, 0.5);
  color: #FFFFFF;
}
.profileImg{
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

@media only screen and (max-width: 990px) {
    .logo_img{
        width: 40px;
    }

    .authBtn{
        padding: 0.2rem 1rem;
        gap: 10px;
        font-size: 10px;
    }
    .authBtnn{
        padding: 0.2rem 1rem;
        font-size: 10px;
    }
    .profile_section{
        width: 207px;
        right: 0;
        top: 0;
        margin-top: 2.813rem;
        font-weight: 400;
        font-size: 15.0091px;
        line-height: 18px;
        margin-right: 3.4rem;
    }
    .drop_down_icon{
        width: 24.91px;
        height: 24.45px;
    }
    .Go_back{
      font-size: 5.51515px;
      line-height: 7px;
      padding: 0.2rem 0.625rem;
    }
    .Go_back img {
      width: 2.68px;
      height: 4.55px;
    }
    .profileImg{
        height: 20px;
        width: 20px;
    }
  }

  @media only screen and (max-width: 990px) {
    .logo2_img{
        height: 56px;
    }
  }