.modal {
  width: 100%;
}
.modal-content {
  border-radius: 12px;
  width: auto;
  padding: 0.65rem;
  box-shadow: 10px 20px 20px 0px rgba(255, 255, 255, 0.2),
    8px 8px 12px 0px rgba(0, 0, 0, 0.4);
}
.re_mod_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 45px;
  color: #1aa05b;
  letter-spacing: -0.01em;
  line-height: 35px;
}
.mod_body {
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: -0.01em;
  color: #000000;
}
.accept_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  background: #1aa05b;
  border-radius: 20px;
  border: 1px solid #1aa05b;
  padding: .2rem 1rem;
}
.cancel_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #1aa05b;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #1aa05b;
  padding: .2rem 1rem;
  margin-left: 1.25rem;
}
.like {
  font-size: 20px;
  position: relative;
  padding-left: 0.625rem;
  padding-top: 0.625rem;
}
.noBorder {
  border: none !important;
  box-shadow: none;
}

.note-text {
  margin-left: 0.8rem;
}
.statusModal .modal-content {
  min-width: 100%;
  height: 60%;
  border-radius: 24px;
  border: 0.5px solid var(--brand-color-green, #1aa05b);
  background: var(--background-white, #fff);
  box-shadow: 5px 10px 10px 0px rgba(255, 255, 255, 0.2),
    4px 4px 6px 0px rgba(0, 0, 0, 0.4);
}
.statusModal .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
}

@media only screen and (max-width: 990px) {
  .like-title {
    padding-left: 6.25rem;
  }
  .re_mod_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: -0.01em;
    color: #1aa05b;
  }
  .mod_body {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: -0.01em;

    color: #000000;
  }
  .accept_btn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #ffffff;
    background: #1aa05b;
    border-radius: 20px;
    border: 1px solid #1aa05b;
    padding: 0.1rem 0.625rem;
  }
  .cancel_btn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #1aa05b;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #1aa05b;
    padding: 0.1rem 0.625rem;
    margin-left: 1.25rem;
  }
  .note-text {
    margin-left: 1.25rem;
  }
  .statusModal .modal-dialog {
    min-width: 60vw !important;
  }
}
@media only screen and (max-width: 480px) {
  .statusModal .modal-dialog {
    min-width: 95vw !important;
  }
  .statusModal .modal-content{
    border-radius: 24px;
    height: 50%;
  }
}
