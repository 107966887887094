.paymentInfos {
  font-family: "Poppins";
}
.paymentInfos h3 {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 1.4rem;
}
.paymentOptions {
  margin: 3rem auto 1rem auto;
  width: 30%;
}

.paymentSections {
  margin: auto;
  width: 32%;
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--brand-color-green, #1aa05b);
  background: var(--background-white, #fff);

  /* select strategy section */
  box-shadow: 4px 8px 4px 0px rgba(0, 0, 0, 0.1),
    5px 4px 12px 0px rgba(0, 0, 0, 0.25);
}

.subscriptionBill,
.payButtons {
  width: 30%;
  margin: auto;
}
.SubscribeDetails {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
}
.SubscribeDetails label {
  display: flex;
  width: 45%;
  gap: 12px;
  border-radius: 24px;
  border: 1px solid var(--gray-light, #b1b1b1);
  align-items: flex-start;
  padding: 0.5rem 1rem;
}
.SubscribeDetails label input {
  margin-top: 0.3rem;
}
.SubscribeDetails label div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.line {
  height: 2px;
  width: 100%;
  border-radius: 2px;
  opacity: 0.4;
  background: #1aa05b;
}
.subTotal,
.planDiscount,
.total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 130% */
}
.total {
  font-weight: 600;
}
.subTotal span,
.planDiscount span,
.total span {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: end;
  color: #000;
}
.subTotal small,
.planDiscount small,
.total small {
  color: #b1b1b1;
  font-weight: 500;
}
.planDiscount {
  color: #1aa05b;
}
.yearlyDiscont {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.agreeTerms label {
  display: flex;
  color: #474747;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  gap: 7px;
  align-items: center;
}
.agreeTerms label p {
  margin: 0;
}
.agreeTerms label a {
  color: #2758ca !important;
}
.agreeTerms label span {
  color: red;
}
.payButtons {
  display: flex;
  gap: 16px;
  margin: 2.2rem auto;
  justify-content: flex-start;
}

@media only screen and (max-width: 1024px) {
  .paymentOptions,
  .paymentSections,
  .subscriptionBill,
  .payButtons {
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .paymentOptions,
  .paymentSections,
  .subscriptionBill,
  .payButtons {
    width: 55%;
  }
}
@media only screen and (max-width: 426px) {
  .paymentInfos {
    padding: 0 1rem;
  }
  .paymentInfos h3 {
    font-size: 20px;
  }

  .SubscribeDetails {
    gap: 24px;
  }
  .total,
  .subTotal,
  .yearlyDiscont {
    gap: 16px;
  }
  .SubscribeDetails label {
    font-size: 12px;
    gap: 4px;
    padding: 0.5rem;
    border-radius: 16px;
  }
  .SubscribeDetails label input {
    margin-top: 0.2rem;
  }
  .paymentOptions {
    margin: 1rem auto;
  }
  .paymentSections {
    padding: 0.8rem;
  }
  .paymentOptions,
  .paymentSections,
  .subscriptionBill,
  .payButtons {
    width: 100%;
  }
  .payButtons {
    margin: 1rem auto 3rem auto;
  }
  .agreeTerms label{
    align-items: flex-start;
  }
  .agreeTerms label input{
    margin-top: .2rem;
  }
}
