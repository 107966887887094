.cardContainer{
    display: flex;
padding: 12px 14px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
border-radius: 24px;
background: #F4F8F7;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.20);
position: relative;
margin: 10px 0;
}

.loadMore{
    color: var(--brand-color-green, #1AA05B);
font-family: Poppins;
font-size: 16px;
font-style: italic;
font-weight: 400;
line-height: 20px; /* 125% */
border: none;
background: transparent;
align-self: flex-end;
}

#div1{
    padding-left: 10px;
position: relative;
}
#div2{
    margin-left: 650px;
    margin-top: 150px;
    margin-bottom: 150px;
}
#ws{
    background-color: whitesmoke;
}
#nb{
    text-decoration: none;
    color: black;
}
#ml{
    margin-left: 20px;
}
#bswm{
color: #FF3131;
text-overflow: ellipsis;
whitespace: nowrap;
font-family: Poppins;
font-size: 24px;
font-style: italic;
font-weight: 400;
line-height: 26px; /* 108.333% */
text-transform: uppercase;
margin: 0;
width: 470px;
}
#pgnw{
    color: var(--brand-color-green, #1AA05B);
font-family: Poppins;
font-size: 16px;
font-style: italic;
font-weight: 700;
line-height: 26px; /* 162.5% */
letter-spacing: -0.16px;
bottom: 5px;
right: 20px;
position: absolute;
}

.saveLikebtn{
    position: absolute;
    top: 12px;
    right: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

#mt{
    margin-top: 40px;
}
#img1{
    margin-left: -58px;
    margin-top: 16px;
    cursor: pointer;
}
#img2{
    margin-left: -38px;
    margin-top: 6px;
    cursor: pointer;
}
#at{
    position: absolute;
    top: 100;
}
#mt1{
 margin-top: "-10px" ;
}
#wh{
     width: "20px";
 height: "20px" ;
}
#c{
    align-items: center;
}
#pt{
    cursor: pointer;
    margin-top: -32px;
}
#bs{
    border-radius: 15px;
}
#cfwm{
color:"brown";
    font-size:"13px";
font-weight:"500";
margin:"30px 0px -30px";
}
#cp{
    cursor:pointer;}
#bw{
     background: "#FFFFFF";
}

@media screen and (max-width:1024px) {
    .cardContainer{
        width: 100%;
    }
}

@media screen and (max-width:767px) {
    #bswm{
        display: -webkit-box;
        width: 290px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: #FF5757;
        text-overflow: ellipsis;
        font-family: Inter;
        font-size: 10px;
        font-style: italic;
        font-weight: 500;
        line-height: 12px; /* 120% */
        text-transform: uppercase;
    }
    #pgnw{
        color: var(--brand-color-green, #1AA05B);
        font-family: Poppins;
        font-size: 8px;
        font-style: italic;
        font-weight: 700;
        line-height: 16px; /* 200% */
        bottom: 4px;
        right: 8px;
    }
    .cardContainer{
        display: flex;
        max-width: 90vw;
        padding: 4px 8px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        border-radius: 12px;
        background: #F4F8F7;
        box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.20);
    }
    .container{
        padding:12px 0px;
    }
    .impGray{
        color: #939CA3 !important;
    }
    .loadMore{
        color: var(--brand-color-green, #1AA05B);
        font-family: Poppins;
        font-size: 8px;
        font-style: italic;
        font-weight: 400;
        line-height: 20px; /* 250% */
        margin-right: 5vw;
    }
    .saveLikebtn{
        top: 2px;
        right: 5px;
    }
    .saveLikebtn img{
        width: 16px;
        height: 16px;
    }
}
@media screen and (max-width:400px) {
    .profileDataWrapper{
        width:90%;
    }
}