.card-counter {
    box-shadow: 2px 2px 10px #dadada;
    margin: 5px;
    padding: 0px 10px;
    background-color: #fff;
    height: 100px;
    border-radius: 5px;
    transition: 0.3s linear all;
  }
  
  .card-counter:hover {
    box-shadow: 4px 4px 20px #dadada;
    transition: 0.3s linear all;
  }
  
  .card-counter.primary {
    background-color: #007bff;
    color: #fff;
  }
  
  .card-counter.danger {
    background-color: #ef5350;
    color: #fff;
  }
  
  .card-counter.success {
    background-color: #66bb6a;
    color: #fff;
  }
  
  .card-counter.info {
    background-color: #26c6da;
    color: #fff;
  }
  
  .card-counter i {
    font-size: 5em;
    opacity: 0.2;
  }
  
  .card-counter .count-numbers {
    right: 35px;
    top: 20px;
    font-size: 32px;
    display: block;
  }
  
  .card-counter .count-name {
    right: 35px;
    top: 65px;
    font-style: italic;
    text-transform: capitalize;
    opacity: 0.5;
    display: block;
    font-size: 18px;
  }
  
  .adminType{
    color: #1AA05B;
    border-bottom: #1AA05B;
    font-size: 24px;
  }
  .adminLogout{
    width:80px;
  }