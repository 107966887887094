.forgot_header{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 48px;
    letter-spacing: -0.01em;
    color: #1AA05B;
}
.forgot_subheader{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #1AA05B;
}
.sendMail_text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 48px;
    color: #1AA05B;
    letter-spacing: -0.01em;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 28px;
    margin-bottom: 110px;
    
}
.send_modal{
    /* margin-top: ; */
    border-radius: 12px;
}
.sendEmail_modal{
    background: #FFFFFF;
    border-radius: 12px;
    /* width: 600px; */
}
.email_icon{
    margin-top: 60px;
    height:90px;
    width: 90px;
}
.verify-modal .modal-content{
  width: fit-content !important;
  height: fit-content !important;
  background: #FFFFFF!important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)!important;
  border-radius: 10px!important;
}
.verify_head{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #1AA05B;
}
.verify_subhead{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #6D747A;
}
.verify_footerT{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #6D747A;
}
.verify_footer{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1AA05B;
}
@media only screen and (max-width: 990px) {
    .send_modal{
       margin-top: 50px;
    }
    .sendEmail_modal{
        width: 100%;
}
    .email_icon{
        margin-top: 50px;
        width: 69px;
        height: 57px;
    }
    .sendMail_text{
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.01em;
        margin-left: 16px;
        margin-right: 13px;
        margin-top: 43px;
        margin-bottom: 150px;
    }
.forgot_header{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #1AA05B;
}
.verify-modal .modal-content{
  width: 100% !important;
  margin-top: 75px!important;
  height: 100%!important;
  margin-left: 0px!important;
  margin-right: 5px!important;
  }
  .modal.show .modal-dialog{
    margin: 0px 10px!important;
  }
  .verify_head{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #1AA05B;
  }
  .verify_subhead{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #6D747A;
  }
  .verify_footerT{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: -0.01em;
    color: #6D747A;
  }
  .verify_footer{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: -0.01em;
    color: #1AA05B;
  }
}

@media only screen and (max-width: 740px) {
  .forgot_header{
    font-size: 25px;
}
}