.stratigy_bg {
  background: #1aa05b;
}
.sub-title {
  color: #1aa05b;
  font-family: "Inter";
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
}
#usercon{
  display: flex;
  margin-left: 3.5%;
  padding: 0.5%;
  color: white;
  font-style: italic;
  background-color: rgb(0, 0, 0);
  width: 25%;
  margin-bottom: 2%;
  border-radius: 20px;
  /* opacity:0.5; */
  /* margin: 1%; */
}
.sub-subtitle {
  font-family: "Inter";
  color: #000;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
}
.search-head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}
.acordonia_coll {
  background: none !important;
}
.card_pad {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  position: relative;
  bottom: 10px;
  background: "#FFFFFF",
}
.Strategy_count {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 0.1rem;
  margin-right: -0.3rem;
  color:#474747;
  text-transform: uppercase;
}
.Strategy_count_article p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}
.counter_str {
  width: 35px;
  height: 35px;
  background: #1aa05b;
  padding: 2px 8px;
  border-radius: 105px;
  margin-left: 5px;
  font-weight: bold;
  color: black;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 8px;
  margin: 0rem;
}
.container_title_sec {
  margin: 0px 1.5rem;
}
.dropDownContainer {
  margin: 0px 1.5rem;
}
.sub_sub_title,.clickLearningText {
  font-family: "Inter";
  color: #000;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px
}
.clickLearningText{
  color: #004AAD;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 0.4rem;
}
.outcomeList{
  padding: 1rem;
  border-radius: 24px;
background: #F4F8F7;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.20);
}
.strategyReadmore{
  width:100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.strategyReadmore a{
  color:  #1AA05B;
font-size: 16px;
font-style: italic;
font-weight: 700;
line-height: 26px; /* 162.5% */
letter-spacing: -0.16px;
text-decoration: none;
height: 16px;
}
.strategyReadmore button{
  color:  #1AA05B;
font-size: 16px;
font-style: italic;
font-weight: 700;
line-height: 26px; /* 162.5% */
letter-spacing: -0.16px;
text-decoration: none;
height: 16px;
background: transparent;
border: none;
}
.card_border {
  border-left: none !important;
  border-top: none !important;
}
.checkbox_size {
  width: 25px;
  height: 25px;
}
.checkBox_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
}
.showStrategy{
  font-size: 24px;
  font-weight: 500;
}
.dsfdasfas {
  width: 200px;
}
.icons_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  background: #1aa05b;
  border-radius: 2px;
  padding: 2px 7.6px;
  margin-left: -68px;
}
.icons_hindi_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  background: #1aa05b;
  border-radius: 2px;
  padding: 2px 33px;
  margin-left: -68px;
}
.threeIcons {
  width: 60px;
  height: 60px;
}
.threeIcons-nun {
  width: 75px;
  height: 60px;
}
.threeIcons-nun-save {
  width: 75px;
  height: 60px;
}
.threeIcons-nunH {
  width: 68px;
  height: 60px;
}
.res_btn_icon {
  width: 70%;
  display: flex;
  align-items: center;
}
.res_btn_icon-sm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.res_inner_div {
  border: 0.5px solid #1aa05b;
  border-radius: 2px;
}
.icon_heading_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 8px;
  text-align: center;
  color: #ffffff;
  background: #1aa05b;
  border-radius: 0.852839px;
}
.find_strategies_btn {
  padding: 8px 16px;
  background: #ffffff;
  border: none;
  border-radius: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #1aa05b;
}
.empty_stratigys {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #000000;
  margin: 100px 100px 100px 100px;
}
.borderNone,.card-header{
  border:none;
  background:none;
}
.linkStyle{
  text-decoration: none;
  color: #000000;
  width: fit-content;
  border: none;
  background-color: transparent;
}
.linkStyle:hover{
  color: #000000;
}
.save_like {
  width: 24px;
  height: 24px;
}
.save_likes{
  width: 32px;
  height: 32px;
}
@media only screen and (max-width: 1440px) {
  .icons_heading {
    margin-left: -4.25rem;
  }
  .icons_hindi_heading {
    margin-left: -4.25rem;
  }
}


@media only screen and (max-width: 990px) {
  .container_title_sec {
    margin: 0.5rem;
    margin-bottom: 0;
  }
  .dropDownContainer {
    margin: 0.5rem;
    margin-top:0rem;
  }
  .sub-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
  .sub-subtitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
  }
  .sub_sub_title {
    color: #000;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    line-height: 24px
  }
  .checkbox_size {
    width: 14px;
    height: 14px;
  margin-top: .1rem !important;
  }
  .checkBox_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }
  .card-header {
    padding: 0px !important;
  }
  .Strategy_count {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
  .Strategy_count_article p {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: justify;
  }
  .icons_heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 5.5px;
    line-height: 12px;
    color: #ffffff;
    background: #1aa05b;
    border-radius: 2px;
    padding: 1.5px 6px;
    margin-left: -28px;
  }
  .icons_hindi_heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 9.8px;
    line-height: 12px;
    color: #ffffff;
    background: #1aa05b;
    border-radius: 2px;
    padding: 2px 6px;
    margin-left: -28px;
  }
  .threeIcons {
    width: 20px;
    height: 20px;
  }
  .threeIcons-nun {
    width: 20px;
    height: 20px;
  }
  .threeIcons-nunH {
    width: 20px;
    height: 20px;
  }
  .counter_str {
    width: 14px;
    height: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    padding: 2px 3px;
  }
  .domain_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 5px;
    line-height: 4px;
  }
  .find_strategies_btn {
    padding: 2px 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
  }
  .save_like {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .save_likes {
    width: 18px;
    height: 18px;
    cursor: pointer;
    transform: scale(1.1)
  }
  .empty_stratigys {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    margin: 100px 80px 100px 80px;
  }
}
.unique_id {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  background: #ebedf0;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 3px 7px;
}
.user_str {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.user_str_border {
  border-top: 1px solid #000000;
}
.titleLineHeight{
  line-height:14px;
}
/* .borderNone > div,.checkBoxUp>div{
  margin-top: 5px !important;
}  */
@media only screen and (max-width: 990px) {
  .threeIcons-nun {
    width: 37px;
    height: 20px;
  }
  .threeIcons-nunH {
    width: 28px;
    height: 20px;
  }
  .unique_id {
    font-size: 6.66667px;
    line-height: 8px;
    display: flex;
    justify-content: center;
    margin: -9px 10px 5px 10px;
  }
  .user_str {
    font-size: 8px;
  }
  .showStrategy{
    font-size: 14px;
  }
  .clickLearningText,.sub-title,.sub-subtitle{
    font-size: 14px;
  }
  .blackshadow{
    padding: 0.5rem;
  }
}
@media only screen and (max-width: 500px) {
  .res_btn_icon {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .res_btn_icon-sm {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .clickLearningText{
    color: #004AAD;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: -0.2px;
    margin-bottom: 0;
  }
  .sub_sub_title{
    font-size: 10px;
  }
  .strategyReadmore a{
    font-size: 10px;
  }
  .strategyReadmore button{
    font-size: 10px;
  }
  .sub-title,.sub-subtitle{
    font-size: 12px;
  }
  .checkBox_title {
    font-size: 12px;
    line-height:unset;
  }
  .container{
    padding:0rem !important;
  }
  .showStrategy{
    font-size: 12px;
  } 
  .outcomeList{
    border-radius: 12px;
    padding: .5rem;
  }
}

.checkBoxUp button{
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
}