.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color:#1AA05B;
}
.table-bordered>:not(caption)>*>* {
  border: 1px solid black !important;
}
.table_overflow{
  overflow-x: scroll;
  overflow-y: auto;
  width: 76vw;
  height: 75vh;
}
.table_overflows{
  overflow-x: scroll;
  overflow-y: auto;
  width: 82vw;
  height: 75vh;
}
.table_overflowR{
  overflow-x: scroll;
  overflow-y: auto;
  width: 76vw;
}
.table_overflowsR{
  overflow-x: scroll;
  overflow-y: auto;
  width: 82vw;
}