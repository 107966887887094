.p {
  margin: 0;
  padding: 0;
}

.childContainer {
  display: none;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 3.75rem;
}

.container p {
  margin: 0;
  padding: 0;
}

.planContainer {
  width: 50.28vw;
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 24px;
  border: 2px solid var(--gray-light, #b1b1b1);
}

.billParent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.billDataContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.greenText {
  color: var(--brand-color-green, #1aa05b);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.subtitle {
  color: var(--gray-text, #474747);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.title {
  color: var(--grey-darkest, #08090a);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.commonBtn {
  display: flex;
  height: 40px;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #1aa05b;
  background: #fff;
  box-shadow: -12px -12px 14px 0px rgba(213, 213, 213, 0.82) inset;
}

.commonBtn:hover {
  border-radius: 24px;
  background: #1aa05b;
  box-shadow: -24px -19px 14px 0px rgba(0, 0, 0, 0.3) inset,
    0px 4px 8px 0px rgba(26, 160, 91, 0.2), 4px 6px 14px 0px #3ae48d inset;
}
.expirySubTitle {
  color: #ff922b;
  font-size: 24px;
  font-weight: 500;
}
.commonBtn:hover .btnText {
  color: var(--grey-white, var(--background-white, #fff));
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.btnText {
  color: var(--brand-color-green, #1aa05b);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16px;
  padding: 0;
  margin: 0;
}
.noActivePlan {
  text-align: center;
  color: #474747;
  margin: 2rem auto !important;
  width: 100%;
  font-size: 20px;
}
.RenewBtn{
  color: #FFF;
  background: #1AA05B;
  text-align: center;
  border-radius: 24px;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
letter-spacing: -0.16px;
display: flex;
width: 191px;
height: 40px;
padding: 16px 24px;
justify-content: center;
align-items: center;
gap: 10px;
border:none;
  box-shadow: -24px -19px 14px 0px rgba(0, 0, 0, 0.30) inset, 0px 4px 8px 0px rgba(26, 160, 91, 0.20), 4px 6px 14px 0px #3AE48D inset;
  
}
.RenewBtn p{
  color: #FFF;
}
@media screen and (max-width: 767px) {
  .userDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 42px;
  }

  .userOrg {
    color: var(--grey-darker, #6d747a);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .profileImgAndText {
    margin-bottom: 0.625rem;
  }

  .container {
    display: none;
  }

  .childContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .planContainer {
    display: flex;
    padding: 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    width: calc(100vw - 32px);
    margin: 0 0.5rem 0.5rem 0.5rem;
  }

  .billDataContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5.436px;
  }

  .title {
    color: var(--grey-darkest, #08090a);
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 10.873px;
    letter-spacing: -0.1px;
  }

  .greenText {
    color: var(--brand-color-green, #1aa05b);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 10.873px;
    letter-spacing: -0.1px;
  }

  .subtitle {
    color: var(--gray-text, #474747);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 10.873px;
    letter-spacing: -0.1px;
  }
  .expirySubTitle{
    font-size: 10px;
  }

  .commonBtn {
    display: flex;
    height: 28px;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    border: 1px solid #1aa05b;
    background: #fff;
    box-shadow: -8px -12px 14px 0px rgba(213, 213, 213, 0.43) inset;
  }

  .commonBtn:hover {
    display: flex;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #1aa05b;
    box-shadow: -16px -8px 11px 0px rgba(0, 0, 0, 0.3) inset,
      0px 2px 4px 0px rgba(26, 160, 91, 0.2), 2px 2px 8px 0px #3ae48d inset;
  }

  .btnText {
    color: var(--brand-color-green, #1aa05b);
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    /* 120% */
    letter-spacing: -0.1px;
  }

  .commonBtn:hover .btnText {
    color: var(--background-white, #fff);
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    /* 120% */
    letter-spacing: -0.1px;
  }

  .btnActive {
    display: flex;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #1aa05b;
    box-shadow: -16px -8px 11px 0px rgba(0, 0, 0, 0.3) inset,
      0px 2px 4px 0px rgba(26, 160, 91, 0.2), 2px 2px 8px 0px #3ae48d inset;
  }

  .btnActive p {
    color: var(--background-white, #fff);
  }
  .RenewBtn{
    height: 24px;
    width: fit-content;
    padding: 6px 12px;
  }
  .RenewBtn p{
    font-size: 10px;
    font-weight: 600;
  }
  .planContainer{
    border:1px solid #B1B1B1;
    border-radius: 8px;
  }
  .billParent{
    align-items: baseline;

  }
  .title{
    margin-bottom: 0rem;
  }
}
